import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Helper } from '../shared/helper';
import { LoginRegisterModalComponent } from '../login-register-modal/login-register-modal.component';
import { CartService } from '../shared/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [Helper, CartService],
})
export class HeaderComponent implements OnInit, OnDestroy {
  session_subscriber: any;
  user_id: string = '';
  server_token: string = '';
  is_language_dropdown: boolean = false;
  @ViewChild('AccountPopup') AccountPopup!: LoginRegisterModalComponent;

  constructor(public helper: Helper, public cart_service: CartService) {}

  ngOnInit(): void {
    var detail = this.helper.common_data.observable.subscribe(async (data) => {
      console.log(data);
      if (data) {
        if (detail) {
          detail.unsubscribe();
        }
        this.helper.loader_service.main_loading = false;
      }
    });

    this.session_subscriber =
      this.helper.common_data.session_observable.subscribe((data) => {
        this.check_detail();
      });
  }

  ngOnDestroy() {
    if (this.session_subscriber) {
      this.session_subscriber.unsubscribe();
    }
  }

  check_detail() {
    if (localStorage['user']) {
      let user = JSON.parse(localStorage['user']);
      if (user && user._id) {
        this.user_id = user._id;
        this.server_token = user.server_token;
      }
    } else {
      this.user_id = '';
      this.server_token = '';
    }
  }

  go_to_shop() {
    if (this.helper.router.url == '/checkout') {
      this.helper.router.navigate(['/shop']);
    } else {
      if (this.helper.common_data.cart_data.is_order_pay) {
        this.helper.router.navigate(['/home/table']);
      } else {
        this.helper.router.navigate(['/home']);
      }
    }
  }

  go_to_home() {
    if (this.helper.common_data.cart_data.is_order_pay) {
      this.helper.router.navigate(['/home/table']);
    } else {
      this.helper.router.navigate(['/home']);
    }
  }
}
