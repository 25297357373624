export var menu_title = {
  profil: 'Profil',
  adresses: 'Adresses',
  paiements: 'Paiements',
  commandes: 'Commandes',
  deconnexion: 'Déconnexion',
};

export var status = {
  order_accepted: 'Order accepted',
  order_prepared: 'Order prepared',
  order_on_way: 'Order on the way',
  order_doorstep: 'Order on doorstep',
  cancelled_by_admin: 'Cancelled By Admin',

  created: 'Created',
  waiting_for_accept: 'Waiting for Accept',
  accepted: 'Accepted',
  start_preparing_order: 'Start Preparing order',
  order_being_prepared: 'Your order is being prepared',
  order_ready: 'Order Ready',
  waiting_for_delivery_man: 'Waiting for Delivery Man',
  delivery_man_accepted: 'Delivery Man Accepted',
  delivery_man_coming: 'Delivery Man Coming',
  delivery_man_arrived: 'Delivery Man Arrived',
  picked_order: 'Delivery Man Picked Order ',
  started_for_delivery: 'Started For Delivery',
  arrived_at_destination: 'Delivery Man Arrived at Destination',
  delivered_ordered: 'Delivered Order',
  assign_order_to_Delivery_man: 'Order Assign To Delivery Man',
  rejected: 'Rejected',
  cancelled: 'Cancelled',
  store_cancelled: 'Cancel By store',
  no_delivery_man_found: 'No Delivery Man Found',
  delivery_man_rejected: 'Delivery Man Rejected',
  delivery_man_cancelled: 'Cancle By Delivery Man',
  user_cancelled: 'Cancle By User',
  oredr_complete: 'Order Complete',
  order_delivered: 'Order Delivered',
  order_completed: 'Order Completed',
  completed: 'Completed',
  not_completed: 'Not Completed',
  cancelled_request: 'Request Cancelled',

  order_status1: 'Votre commande est en cours d’acceptation',
  order_status2: 'Votre commande a été acceptée',
  order_status3: 'Votre commande est prête, vous pouvez venir la chercher à :',
  order_status4: 'Votre Hopper (livreur) est en route vers :',
  order_status5: 'Votre commande a été récuperée',
  order_status6: 'Votre commande est livrée.',
};

export var title = {
  mandatory_choice: 'choix obligatoire',
  max_choice: 'choix maximum',
  table: 'Table',
  table_title: 'Commandez depuis votre table et soyez servi par un serveur.',
  commandez_en_ligne_chez: 'Commandez en ligne chez',
  da_gabriele: 'Da gabriele',
  new_password: 'Changer de mot de passe...',
  ouvert: 'Ouvert',
  quel_type_de_commande: 'Quel type de commande ?',
  quand: 'Quand ?',
  a_quelle_adresse: 'À quelle adresse ?',
  schedule_time_error:
    'Oups, le partenaire n’est pas ouvert à l’heure et à la date sélectionnée, veuillez changer d’horaire',
  emergency_time_error:
    'Livraison uniquement disponible en précommande actuellement ',
  reopen_at: 'Réouverture : ',
  order_at: 'Commande à partir de',
  store_closed_today: 'Partenaire fermé',
  please_add_card_first: 'veuillez ajouter une carte de crédit',
  vos_adresse_enregistrees: 'Vos adresse enregistrées',
  modifier: 'Modifier',
  supprimer: 'Supprimer',
  social_connect_title: 'Ou s’inscrire avec :',
  vos_commandes_deja_passees: 'Vos commandes déjà passées',
  vos_commandes_deja_passees1:
    'Vous n’avez pas encore passé de commande chez nous ',
  comment_trade1: 'Commentaire pour le commerce',
  comment_trade2: '(facultatif)',
  finalisez_votre_commande: 'Finalisez votre commande',
  resume_de_votre_commande: 'Résumé de votre commande',
  detail_du_montant_payer: 'Détail du montant à payer',
  connectez_vous_pour_payer: 'Connectez-vous pour payer',
  today: 'today',
  type_de_commande: 'Type de commande',
  confirmer_votre_adresse: 'Confirmer votre adresse',
  moyen_de_paiement: 'Moyen de paiement :',
  forgot_popup_title: 'Vous avez oublie votre mot de passe ?',
  carte_de_credit_selectionnee: 'Carte de crédit sélectionnée',
  credit_hop_disponible: 'Crédit HOP disponible',
  commandes_en_cours: 'Commandes en cours',
  historique: 'Historique',
  ajouter_une_carte: 'Ajouter une carte',
  point_de_retrait: 'Point de retrait (A.) :',
  lieu_de_livraison: 'Lieu de livraison (B.)',
  tracking: 'Tracking',
  deli_list_text1:
    'Votre commande est enregistrée, nous allons lui assigner un Hopper',
  deli_list_text2: 'Votre commande sera effectuée par :',
  deli_list_text3: 'Votre Hopper est en route vers l’adresse A',
  deli_list_text4: 'Votre Hopper est en route vers l’adresse B',
  cancelled_by_admin: 'Cancelled By Admin',
  deli_list_text5: 'Votre commande est livrée.',
  promo_error: "Le code promo n'est pas valable",
  promo_min_amount_error:
    "Le code promo est valable pour les commandes d'au moins",
  order_number: 'Votre numéro de commande',
  order_status: 'Statut  de votre commande :',
  restons_disposition: 'Nous restons à votre disposition !',
  cancel_order_title: 'Pour quelle raison voulez-vous annuler votre commande ?',
  da_gabriele_est_ferme: ' est fermé.',

  di: 'Di',
  lu: 'Lu',
  mar: 'Mar',
  mer: 'Mer',
  jeu: 'Jeu',
  ven: 'Ven',
  sam: 'Sam',

  buy_one_get_one_offer: 'Voulez-vous profiter de la promotion 1+1 gratuit ?',
  oui: 'OUI',
  non: 'NON',
  promotion_gratuit: 'promotion 1+1 gratuit',
};

export var button = {
  clear: 'Clear',
  catering: 'Catering',
  se_connecter: 'Se connecter',
  en_livraison: 'En livraison',
  table_order: 'Commande à table',
  en_click_collect: 'En Click&Collect',
  voir_le_menu: 'Voir le menu',
  delivery_area_link: 'Voir la zone de livraison',
  fermer: 'Fermer',
  retour: 'Retour',
  deli_area_link: 'Voir la zone de livraison',
  des_que_possible: 'Dès que possible',
  plus_tard: 'Plus tard',
  modifier: 'Modifier',
  continuer: 'Continuer',
  envoyer: 'Envoyer',
  en_savoir_plus: 'En savoir plus...',
  save: 'save',
  voir_plus: 'Afficher tout',
  voir_minus: 'Voir moins',
  panier: 'Panier',
  re_order_btn: 'Re-commander une commande passée',
  re_order_btn1: 'Connectez-vous pour re-commander une ancienne commande',
  fermer_le_panier: 'Fermer le panier',
  cart_d_btn: 'Commander !',
  s_inscrire: 'S’inscrire',
  mot_de_passe_oublie: 'Mot de passe oublié',
  available_product: 'Ajouter au panier les produits disponibles',
  available_product1: 'Ajouter au panier',
  ajouter: 'Ajouter',
  retour_a_l_accueil: 'Retour à l’accueil',
  ajouter_des_produits: 'Ajouter des produits',
  voir_votre_panier: 'Voir votre panier',
  voir_votre_factures: 'Voir votre facture',
  appliquer: 'Appliquer',
  utiliser_une_autre_carte: 'Changer carte',
  passer_la_commande: 'Passer la commande',
  changer_de_carte: 'Changer de carte',
  revenir_au_panier: 'Revenir au panier',
  ajouter_une_nouvelle_carte: 'Ajouter une nouvelle carte',
  utiliser_cette_nouvelle_carte: 'Utiliser cette nouvelle carte',
  sauvegarder: 'Sauvegarder',
  ajouter_une_adresse: 'Ajouter une adresse',
  ajouter_une_carte_de_credit: 'Ajouter une carte de crédit',
  supprimer_la_selection: 'Supprimer la sélection',
  poursuivre: 'Poursuivre',
  voir_la_zone_de_livraison: 'Voir le périmètre de livraison',
  annuler: 'Annuler',
  valider: 'Valider',
  suivi_de_la_commande: 'Suivi de la commande',
  indisponible_actuellement: 'Indisponible actuellement',
  indisponible: 'Indisponible',
  actuellement: 'actuellement',
  ok_ai_compris: 'Ok, j’ai compris',
  voir_detail_de_la_commande: 'Voir détail de la commande',
  ajouter_un_autre_produit: 'Ajouter un autre produit',
  ok_ai_compris2: 'Ok, j’ai compris !',
  envoyer_un_nouveau_mot_de_passe: 'Envoyer un nouveau mot de passe',
  annuler_votre_commande: 'Annuler votre commande',
  confirmer: 'confirmer',
  agrandir_image: 'Agrandir l’image',
};

export var heading_title = {
  perimetre_de_livraison: 'Périmètre de livraison',
};

export var placeholder = {
  table_no: 'Numero de table',
  deli_address: 'Veuillez entrer une adresse complète...',
  email_address: 'Entrez votre adresse mail...',
  entrez_votre_adresse: 'Entrez votre adresse complète...',
  entrez_votre_comment: 'Entrez votre votre commentaire...',
  address_field_text1: 'Entrez l’adresse complète du point de retrait...',
  content_number: 'Ex. : Contenu de la commande, numéro de commande, etc.',
  john_doe: 'ex. : john@gmail.com',
  XXXXXXXX: 'XXXXXXXX',
  doe: 'Ex. : Doe',
  john: 'Ex. : John',
  email: 'Ex. : johndoe05@gmail.com',
  password: 'Choisissez un mot de passe sécurisé',
  phone_number: 'XX XXX XX XX',
  address_name: "Nom de l'adresse",
  address: 'Adresse',
  etage: 'Etage',
  code_entree: "Code d'entrée",
  commentaire: 'Commentaire',
  code_promotion: 'Code promotion...',
  code_promo: 'Code Promo',
  seul_le_livreur_verra_votre_commentaire:
    'Seul le livreur verra votre commentaire...',
  seul_le_order_pay_verra_votre_commentaire:
    'Commentaire pour la cuisine / service ...',
  mm_aa: 'MM/AA',
  reason_text: 'Entrez ici la raison de votre annulation...',
};

export var label = {
  0: 'Dimanche',
  1: 'Lundi',
  2: 'Mardi',
  3: 'Mercredi',
  4: 'Jeudi',
  5: 'Vendredi',
  6: 'Samedi',
  ferme: 'Fermé',
  reouverture: 'Réouverture :',
  date: 'Date',
  heure: 'Heure',
  populaire: 'Populaire',
  adresse_email: 'Adresse email :',
  adresse_email2: 'Adresse email',
  mot_de_passe: 'Mot de passe',
  nom: 'Nom :',
  nom2: 'Nom',
  prenom: 'Prénom :',
  prenom2: 'Prénom',
  numero_de_telephone: 'Numéro de téléphone :',
  numero_de_telephone2: 'Numéro de téléphone',
  total: 'Total',
  etage: 'Étage (facultatif)',
  code_d_entree: 'Code d’entrée (facultatif)',
  commentaire_pour_hopper: 'Commentaire pour hopper  (livreur)',
  commentaire_pour_order_pay: 'Commentaire pour votre commande (facultatif)',
  numero_de_carte: 'Numéro de carte',
  date_d_expiration: 'Date d’expiration',
  ccv: 'CCV',
  montant_total: 'Montant total :',
  numero_de_commande: 'Numéro de commande :',
  ready_time_title: 'Votre commande sera prête à :',
  delivery_time_title: 'Votre commande sera livrée',
  delivery_time_title1: 'Vous pourrez venir chercher votre commande le',
  complete_time_title: 'Terminé à :',
  cancel_time_title: 'Annulé à :',
  disponible_des: 'Disponible dès :',
  par: 'Par',
  a: 'À',
  nouveau_mot_de_passe: 'Nouveau mot de passe',
};

export var text = {
  home_top_text: 'test',
  powered_by: 'Powered by',
  pre_order_text: 'Précommande uniquement disponible',
  pre_order_text1: 'Actuellement livraison uniquement en précommande',
  see_menu_title: 'Merci pour votre contribution !',
  see_menu_text:
    'Nous vous recontacterons dès que votre adresse sera desservie.',
  deli_add_text: 'Où souhaitez-vous être livré ?',
  deli_add_text2: 'Quand souhaitez-vous être livré ?',
  deli_add_text3: 'Quand souhaitez-vous récupérer votre commande ?',
  order_deli_time: 'Votre commande sera livrée à :',
  order_deli_time1: 'Vous pourrez récupérer votre commande à :',
  pre_order_only: 'Seul les précommandes sont disponibles actuellement',
  not_deliver_text: 'nous ne livrons pas encore à cette adresse...',
  oops: 'Oops, ',
  send_addess: 'Envoyez nous votre adresse e-mail pour le faire savoir.',
  d_alert_msg11: 'Le Livraison est momentanément indisponible.',
  d_alert_msg1: 'Le Click&Collect est momentanément indisponible.',
  d_alert_msg2: 'Seul les précommandes sont disponibles actuellement.',
  d_alert_msg3: 'Nous ne livrons pas encore à cette adresse.',
  d_alert_msg4: 'Veuillez entrer une adresse pouvoir passer commande',
  livraison_estimee: 'Livraison estimée à :',
  shop_footer_text:
    '⭐ Plus que CHF 80 pour profiter de - 30% sur votre commande ⭐',
  cart_dropdown_footer_text: 'Frais de livraisons offerts dès 120 CHF ',
  apple_text: 'Apple',
  google_text: 'Google',
  fb_text: 'Facebook',
  condition_text1: 'J’accepte les',
  condition_text2: 'Conditions générales d’utilisation',
  condition_text3: 'ainsi que la',
  condition_text4: 'Politique de confidentialité',
  pay_text1: 'Connectez-vous pour passer commande',
  pay_text4: 'Connectez-vous pour ajouter un moyen de paiement',
  pay_text2: '(ou vous inscrire si ce n’est pas encore fait)',
  pay_text3:
    'Pour re-commander une précédente commande, merci de vous connecter ',
  missing_choice_text: 'Choix manquant pour ',
  sous_total: 'Sous-total',
  frais_de_hopper: 'Frais de Hopper',
  frais_de_service: 'Frais de service',
  order_ready: 'Votre commande sera prête à ...',
  no_valid_promo: 'Le code promotion entré n’est pas valide',
  use_wallet_text: 'Utiliser mon Wallet',
  card_error_text: 'Votre carte de crédit semble ne pas fonctionner',
  support_contact: 'Contactez le support Hop',
  card_edit_error_text: 'Le numéro de carte entré n’est pas valide',
  forgot_text:
    'Entrez votre adresse mail et nous vous en enverrons un nouveau.',
  livraison_offerte_text: 'Livraison offerte',
  thanku_order_msg: 'Merci pour votre commande !',
  empty_cart_text: 'Votre panier est  actuellement vide',
  alert_text1:
    'En raison d’un nombre de commandes très élevé et afin d’assurer une qualité optimale pour les livraisons en cours nous avons temporairement stoppé le service de livraison',
  alert_text2:
    'Vous pouvez toutefois effectuer une précommande ou commander en Click&Collect',
  alert_text3:
    'Oops, votre commande a été annulée par “Personne qui a annuler”',
  moa: 'Oops, montant minimum pour commander : ',
  tooltip1: 'Ce montant a été ajouté par le partenaire',
  tooltip2: "Votre hopper (livreur) touchera l'entièreté de ce montant ️😊",
  tooltip3: 'Le Wallet est votre portefeuillle digital de chez Hop',
  order_status1: 'Votre commande a été acceptée',
  alert_msg1: 'Veuillez entrer une entrée valide”',
  alert_msg2:
    'Pour continuer, vous devez accepter les conditions générales et la politique de confidentialité',
  alert_msg3: 'Ce mot de passe n’est pas correct',
  alert_msg4: 'Cette adresse email est incorrect ou n’existe pas.',
  alert_msg5: 'téléphone déjà enregistré',
  moa_alert_text: 'Le montant minimum de commande est de : ',
  new_user_signup: 'Merci pour votre inscription !',
  new_user_signup_text:
    'Vous pourrez utiliser ces identifiants sur “www.hop.delivery” ou sur les autres commerces partenaires de Hop.',
  user_already_account:
    'Il semblerait que vous ayez déjà un compte HOP avec cet email : ',
  user_account_note:
    'Vous avez crée un compte sur un autre site propulsé par HOP, par conséquent vous pouvez utiliser les même identifiants sur les différents partenaires travaillant avec Hop.',
  new_pass_text: 'Un nouveau mot de passe à bien été envoyé à :',
  reason_text: 'Raison : “Trop de commandes en ligne, navré.”',
  reason_content1:
    'La montant de la commande a été automatiquement remboursé sur votre compte sous forme de crédits.',
  reason_content2: 'Ces crédits peuvent être utilisés à tout moment.',
  reason_content3:
    'Ceux-ci peuvent également être utilisés sur la plateforme HOP (www.hop.delivery).',
  cancel_list1: 'Il manque un ou des produit(s)',
  cancel_list2: 'L’adresse de livraison n’est pas correcte',
  cancel_list3: 'Autre raison',
  command_not_available: 'Les commandes ne sont pas disponibles',
  various_orders: 'Rendez-vous sur HOP pour vos différentes commandes :',
};

export var validation_message = {};

export var success_message = {};

export var error_message = {
  order_alert_text1: '“Nom de l’option” n’est plus disponible',
  order_alert_text2: '“Burger double cheese” n’est plus disponible',
  804: 'Business not in your country.',
  965: 'Your cart store not available in this region.',
  843: 'Business or service not available in this city.',
  551: 'le code promo a déjà été utilisé',
  552: 'Promo code not for city.',
  553: 'Your delivery charge free you can not apply promo.',
  652: 'Order Not Found',
};

export var ERROR_CODE = {
  804: 'Business not in your country.',
  965: 'Your store not available in this region.',
  843: 'Business or service not available in this city.',
  551: 'Promo code already used.',
  552: 'Promo code not for city.',
  553: 'Your delivery charge free you can not apply promo.',
  EMAIL_NOT: 512,
  PASSWORD_NOT: 513,
  EMAIL_ALREADY: 502,
  PHONE_ALREADY: 503,
  FORGOT_EMAIL_NOT: 1000,
};

export var MESSAGE_CODE = {};
